<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('new_register')" :isFilter="false"></Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('new_register')" :isFilter="false"></HeaderMobile>
            </template>
            <div class="row data-form register_form">
                <div class="col-12 col-md-12">
                    <ValidationObserver ref="registerForm">
                        <div class="border px-5 pt-5 pb-2 mb-1 rounded">
                            <div class="row">
                                <div class="col-12 mb-2">
                                    <b-badge variant="primary" @click="changeStudentType" v-if="!foreignStudent">
                                        {{ $t("student") }}
                                    </b-badge>
                                    <b-badge variant="warning" @click="changeStudentType" v-if="foreignStudent">
                                        {{ $t("foreign_student") }}
                                    </b-badge>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <ValidationProvider name="semester_id" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('semester')">
                                            <semesters-selectbox
                                                :validate-error="errors[0]"
                                                :setActive="true"
                                                v-model="registerForm.semester_id">
                                            </semesters-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <ValidationProvider name="type" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('registration_type')">
                                            <parameter-selectbox
                                                code="registration_types"
                                                :validate-error="errors[0]"
                                                v-model="registerForm.type">
                                            </parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <ValidationProvider name="scholarship_rate" rules="required"
                                                        v-slot="{valid, errors}">
                                        <b-form-group :label="$t('scholarship_rate')">
                                            <scholarship-rate-selectbox
                                                :validateError="errors[0]"
                                                v-model="registerForm.scholarship_rate">
                                            </scholarship-rate-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <ValidationProvider name="faculty" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('faculty')">
                                            <faculty-selectbox
                                                :validateError="errors[0]"
                                                v-model="registerForm.faculty">
                                            </faculty-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <ValidationProvider name="department" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('department')">
                                            <department-selectbox
                                                :faculty_code="registerForm.faculty"
                                                :validateError="errors[0]"
                                                v-model="registerForm.department">
                                            </department-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <ValidationProvider name="program_code" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('program')">
                                            <program-selectbox
                                                :faculty_code="registerForm.faculty"
                                                :department_code="registerForm.department"
                                                :is-active="true"
                                                :validateError="errors[0]"
                                                v-model="registerForm.program_code">
                                            </program-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <b-tabs content-class="py-5 position-relative" class="mt-5" v-model="tabIndex"
                                :key="renderCount">
                            <div class="tabs-bottom-line"></div>
                            <b-tab active ref="tab1">
                                <template #title><i class="ri-profile-line top-plus-2 mr-2"></i>{{
                                        foreignStudent == true ? $t('foreign_student_identity') : $t('identity')
                                    }}
                                </template>
                                <div class="row" v-if="!foreignStudent">
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="national_id"
                                                            :rules="foreignStudent == false ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('tc_no')">
                                                <b-form-input
                                                    v-mask="'###########'"
                                                    v-model="registerForm.national_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="name"
                                                            :rules="foreignStudent == false ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('name')">
                                                <b-form-input
                                                    v-model="registerForm.name"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="surname"
                                                            :rules="foreignStudent == false ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('surname')">
                                                <b-form-input
                                                    v-model="registerForm.surname"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="birthdate"
                                                            :rules="foreignStudent == false ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('date_of_birth')">
                                                <select-date v-model="registerForm.birthdate"
                                                             :validation-error="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="birthplace"
                                                            :rules="foreignStudent == false ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('birthplace')">
                                                <b-form-input
                                                    v-mask="upperAlphaMask"
                                                    v-model="registerForm.birthplace"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="gender"
                                                            :rules="foreignStudent == false ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('gender')">
                                                <gender-selectbox
                                                    :validate-error="errors[0]"
                                                    v-model="registerForm.gender">
                                                </gender-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="nationality_code"
                                                            :rules="foreignStudent == false ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('nationality')">
                                                <country-selectbox
                                                    valueType="code"
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.nationality_code">
                                                </country-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="mother_name"
                                                            :rules="foreignStudent == false ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('mother_name')">
                                                <b-form-input
                                                    v-mask="upperAlphaMask"
                                                    v-model="registerForm.mother_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="father_name" rules="required"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('father_name')">
                                                <b-form-input
                                                    v-mask="upperAlphaMask"
                                                    v-model="registerForm.father_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="city_id" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('city_name')">
                                                <city-selectbox
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.city_id">
                                                </city-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="district_id" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('district_name')">
                                                <district-outline-selectbox
                                                    :city_id="registerForm.city_id"
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.district_id">
                                                </district-outline-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row" v-if="foreignStudent">
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="name"
                                                            :rules="foreignStudent == true ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('name')">
                                                <b-form-input
                                                    type="text"
                                                    v-model="registerForm.name"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="surname"
                                                            :rules="foreignStudent == true ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('surname')">
                                                <b-form-input
                                                    v-model="registerForm.surname"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="gender"
                                                            :rules="foreignStudent == true ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('gender')">
                                                <gender-selectbox
                                                    :validate-error="errors[0]"
                                                    v-model="registerForm.gender">
                                                </gender-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="birthdate"
                                                            :rules="foreignStudent == false ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('date_of_birth')">
                                                <select-date v-model="registerForm.birthdate"
                                                             :validation-error="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="birthplace"
                                                            :rules="foreignStudent == false ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('birthplace')">
                                                <b-form-input
                                                    v-mask="upperAlphaMask"
                                                    v-model="registerForm.birthplace"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="nationality_code"
                                                            :rules="foreignStudent == true ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('nationality')">
                                                <country-selectbox
                                                    valueType="code"
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.nationality_code">
                                                </country-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="father_name"
                                                            rules="required"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('father_name')">
                                                <b-form-input
                                                    v-mask="upperAlphaMask"
                                                    v-model="registerForm.father_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="mother_name"
                                                            rules="required"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('mother_name')">
                                                <b-form-input
                                                    v-mask="upperAlphaMask"
                                                    v-model="registerForm.mother_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="foreign_document_type"
                                                            :rules="foreignStudent == true ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('foreign_document_type')">
                                                <parameter-selectbox
                                                    code="foreign_document_types"
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.foreign_document_type">
                                                </parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="foreign_document_number"
                                                            :rules="foreignStudent == true ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('foreign_document_number')">
                                                <b-form-input
                                                    v-model="registerForm.foreign_document_number"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="passport_country_id"
                                                            :rules="foreignStudent == true ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('passport_country')">
                                                <country-selectbox
                                                    valueType="id"
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.passport_country_id">
                                                </country-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="passport_number"
                                                            :rules="foreignStudent == true ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('passport_number')">
                                                <b-form-input
                                                    v-model="registerForm.passport_number"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="residence_permit_no" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('residence_permit_no')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.residence_permit_no"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="residence_permit_start_date" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('residence_permit_start_date')">
                                                <select-date v-model="registerForm.residence_permit_start_date"
                                                             :validation-error="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <ValidationProvider name="residence_permit_end_date" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('residence_permit_end_date')">
                                                <select-date v-model="registerForm.residence_permit_end_date"
                                                             :validation-error="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab ref="tab2">
                                <template #title><i class="ri-map-pin-line top-plus-2 mr-2"></i>{{ $t('address') }}
                                </template>
                                <div class="row">
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="address_city_id" rules="required"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('city')">
                                                <city-selectbox
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.contact_city_id">
                                                </city-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="address_district_id" rules="required"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('district')">
                                                <district-outline-selectbox
                                                    :city_id="registerForm.contact_city_id"
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.contact_district_id">
                                                </district-outline-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <ValidationProvider name="contact_address" rules="required"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('address')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.contact_address"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab ref="tab3">
                                <template #title><i class="ri-team-line top-plus-2 mr-2"></i>{{ $t('family_info') }}
                                </template>
                                <div class="row">
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="mothers_job" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('mother_job')">
                                                <parameter-selectbox
                                                    @input="handleJobInput($event, 'mother')"
                                                    code="jobs"
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.mothers_job">
                                                </parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="mothers_education" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('mothers_education')">
                                                <parameter-selectbox
                                                    code="education_statuses"
                                                    sort="rank"
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.mothers_education">
                                                </parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="mothers_phone" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('mother_gsm')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.mothers_phone"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4"
                                         v-if="registerForm.mothers_job == 'Diğer'">
                                        <ValidationProvider name="mothers_job_other"
                                                            rules="required_if:mothersJob,Diğer"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('job_other')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.mothers_job_other"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="fathers_job" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('father_job')">
                                                <parameter-selectbox
                                                    @input="handleJobInput($event, 'father')"
                                                    code="jobs"
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.fathers_job">
                                                </parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="fathers_education" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('fathers_education')">
                                                <parameter-selectbox
                                                    code="education_statuses"
                                                    :validateError="errors[0]"
                                                    sort="rank"
                                                    v-model="registerForm.fathers_education">
                                                </parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="fathers_phone" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('father_gsm')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.fathers_phone"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4"
                                         v-if="registerForm.fathers_job == 'Diğer'">
                                        <ValidationProvider name="fathers_job_other"
                                                            rules="required_if:fathersJob,Diğer"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('job_other')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.fathers_job_other"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab ref="tab4">
                                <template #title><i class="ri-phone-line top-plus-2 mr-2"></i>{{ $t('contact') }}
                                </template>
                                <div class="row">
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="mobile_tel"
                                                            :rules="foreignStudent == false ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('gsm_no')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-mask="'(5##)-###-####'"
                                                    v-model="registerForm.mobile_tel"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="home_tel" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('home_tel')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.home_tel"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="email" rules="email" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('email')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.email"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 mb-2 mt-4">
                                        <h6 class="text-uppercase mb-0">{{ $t('emergency_contact_txt').toUpper() }}</h6>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="emergency_person_relation" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('degree_of_proximity')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.emergency_person_relation"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="emergency_person_name" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('name_surname')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.emergency_person_name"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="emergency_person_phone" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('mobile_number')">
                                                <b-form-input
                                                    v-mask="'(5##)-###-####'"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.emergency_person_phone"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab ref="tab5">
                                <template #title><i class="ri-pulse-line top-plus-2 mr-2"></i>{{ $t('health') }}
                                </template>
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                        <ValidationProvider name="surgery_illness_history" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('surgery_illness_history')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.surgery_illness_history"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                        <ValidationProvider name="medicines" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('medicines')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.medicines"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="type_of_disability" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('disability_type')">
                                                <parameter-selectbox
                                                    code="disabled_types"
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.type_of_disability">
                                                </parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="disability_rate" rules="" v-slot="{ errors}">
                                            <b-form-group :label="$t('disability_rate')">
                                                <b-form-select
                                                    v-model="registerForm.disability_rate">
                                                    <option value="">{{ $t('select') }}</option>
                                                    <option v-for="(row, rowindex) in 20" :key="rowindex"
                                                            :value="row*5">{{ row * 5 }}
                                                    </option>
                                                </b-form-select>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab ref="tab6">
                                <template #title><i class="ri-building-line top-plus-2 mr-2"></i>{{
                                        $t('education_info')
                                    }}
                                </template>
                                <div class="row">
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="last_education_status" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('last_education_status')">
                                                <parameter-selectbox
                                                    code="education_statuses"
                                                    :validateError="errors[0]"
                                                    sort="rank"
                                                    v-model="registerForm.last_education_status">
                                                </parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div v-if="checkLastStatus" class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="university_status" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('university_status')">
                                                <multiselect v-model="registerForm.university_status"
                                                             :options="university_type"
                                                             :multiple="false"
                                                             :select-label="''"
                                                             :selected-label="''"
                                                             :deselect-label="''"
                                                             :placeholder="$t('select')"
                                                             :searchable="true"
                                                />
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"
                                                                         class="mb-2"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4"
                                         v-if="registerForm.last_education_status != 'high_school'">
                                        <ValidationProvider name="last_university" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('last_school')">
                                                <university-with-filter-selectbox
                                                    :validateError="errors[0]"
                                                    :is-turkey="registerForm.university_status"
                                                    v-model="registerForm.last_university_id">
                                                </university-with-filter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4"
                                         v-if="registerForm.last_education_status == 'high_school'">
                                        <ValidationProvider name="last_school" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('last_school')">
                                                <school-selectbox
                                                    valueType="code"
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.school_code">
                                                </school-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="last_school_registration_date" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('last_school_registration_date')">
                                                <select-date v-model="registerForm.last_school_registration_date"
                                                             :validation-error="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="last_school_date_of_graduation" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('last_school_date_of_graduation')">
                                                <select-date v-model="registerForm.last_school_date_of_graduation"
                                                             :validation-error="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="last_school_diploma_number" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('last_school_diploma_number')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.last_school_diploma_number"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="last_school_diploma_point" rules="double"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('last_school_diploma_point')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.last_school_diploma_point"
                                                    v-decimal-numbers
                                                    max="100"
                                                    min="0"
                                                ></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab ref="tab7">
                                <template #title><i
                                    class="ri-building-line top-plus-2 mr-2"></i>{{ $t('requests_info') }}
                                </template>
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <ValidationProvider name="want_leave_of_absence" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('want_leave_of_absence')"
                                                          v-slot="{ ariaDescribedby }">
                                                <b-form-radio-group v-model="registerForm.want_leave_of_absence"
                                                                    :aria-describedby="ariaDescribedby">
                                                    <b-form-radio value="E">{{ $t('yes') }}</b-form-radio>
                                                    <b-form-radio value="H">{{ $t('no') }}</b-form-radio>
                                                </b-form-radio-group>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12"
                                         v-if="registerForm.want_leave_of_absence=='E'">
                                        <div class="row">
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                                <ValidationProvider name="reason_for_leave_of_absence"
                                                                    :rules="registerForm.want_leave_of_absence == 'E' ? 'required' : ''"
                                                                    v-slot="{valid, errors}">
                                                    <b-form-group :label="$t('leave_of_absence_reason')">
                                                        <parameter-selectbox
                                                            code="leave_of_absence_reasons"
                                                            :validateError="errors[0]"
                                                            v-model="registerForm.reason_for_leave_of_absence">
                                                        </parameter-selectbox>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                                <ValidationProvider name="leave_of_absence_semesters"
                                                                    :rules="registerForm.want_leave_of_absence == 'E' ? 'required' : ''"
                                                                    v-slot="{valid, errors}">
                                                    <b-form-group :label="$t('leave_of_absence_semesters')">
                                                        <b-form-select
                                                            v-model="registerForm.leave_of_absence_semesters"
                                                            :options="leaveOfAbsenceSemesterOptions"></b-form-select>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12" v-if="programLanguage == 'TR'">
                                        <ValidationProvider name="want_optional_prep" rules="" v-slot="{valid, errors}">
                                            <b-form-group v-slot="{ ariaDescribedby }">
                                                <label>{{ $t('want_optional_prep') }} *</label>
                                                <b-form-radio-group v-model="registerForm.want_optional_prep"
                                                                    :aria-describedby="ariaDescribedby">
                                                    <b-form-radio value="E">{{ $t('yes') }}</b-form-radio>
                                                    <b-form-radio value="H">{{ $t('no') }}</b-form-radio>
                                                </b-form-radio-group>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12" v-if="programLanguage == 'EN'">
                                        <ValidationProvider name="has_english_proficiency_certificate" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group v-slot="{ ariaDescribedby }">
                                                <label>{{ $t('do_you_have_language_proficiency') }}</label>
                                                <b-form-radio-group
                                                    v-model="registerForm.has_english_proficiency_certificate"
                                                    :aria-describedby="ariaDescribedby">
                                                    <b-form-radio value="E">{{ $t('yes') }}</b-form-radio>
                                                    <b-form-radio value="H">{{ $t('no') }}</b-form-radio>
                                                </b-form-radio-group>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div class="row" v-if="registerForm.has_english_proficiency_certificate == 'E'">
                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                                <ValidationProvider name="english_proficiency_certificate_type"
                                                                    vid="english_proficiency_certificate_type"
                                                                    :rules="registerForm.has_english_proficiency_certificate == 'E' ? 'required' : ''"
                                                                    v-slot="{valid, errors}">
                                                    <b-form-group :label="$t('cert_type')">
                                                        <parameter-selectbox
                                                            code="english_proficiency_certificate_types"
                                                            :validateError="errors[0]"
                                                            v-model="registerForm.english_proficiency_certificate_type">
                                                        </parameter-selectbox>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                                <ValidationProvider name="english_proficiency_exam_date"
                                                                    :rules="registerForm.has_english_proficiency_certificate == 'E' ? 'required' : ''"
                                                                    v-slot="{valid, errors}">
                                                    <b-form-group :label="$t('exam_date')">
                                                        <b-form-input
                                                            type="date"
                                                            v-model="registerForm.english_proficiency_exam_date">
                                                        </b-form-input>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                                <ValidationProvider name="english_proficiency_exam_score"
                                                                    :rules="registerForm.has_english_proficiency_certificate == 'E' ? 'required' : ''"
                                                                    v-slot="{valid, errors}">
                                                    <b-form-group :label="$t('exam_score')">
                                                        <b-form-input
                                                            v-model="registerForm.english_proficiency_exam_score">
                                                        </b-form-input>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4"
                                                 v-if="this.registerForm.english_proficiency_certificate_type == 'toefl'">
                                                <ValidationProvider name="toefl_username"
                                                                    rules="required_if:english_proficiency_certificate_type,toefl"
                                                                    v-slot="{valid, errors}">
                                                    <b-form-group>
                                                        <label>{{ $t('toefl_username') }}</label>
                                                        <b-form-input
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            v-model="registerForm.toefl_username"></b-form-input>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4"
                                                 v-if="this.registerForm.english_proficiency_certificate_type == 'toefl'">
                                                <ValidationProvider name="toefl_password"
                                                                    rules="required_if:english_proficiency_certificate_type,toefl"
                                                                    v-slot="{valid, errors}">
                                                    <b-form-group>
                                                        <label>{{ $t('toefl_password') }} *</label>
                                                        <b-form-input
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            v-model="registerForm.toefl_password"></b-form-input>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </ValidationObserver>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex">
                    <b-form-group class="mr-4">
                        <b-button variant="primary" @click="sendForm">{{ $t('save') }}</b-button>
                    </b-form-group>
                    <b-form-group v-if="registerForm.national_id!=null && registerForm.birthdate!=null">
                        <b-button variant="primary" @click="getMernisInformation">{{
                                $t('mernis_information')
                            }}
                        </b-button>
                    </b-form-group>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

import {ValidationObserver, ValidationProvider} from "vee-validate";
import GenderSelectbox from "@/components/interactive-fields/GenderSelectbox";
import CountrySelectbox from "@/components/interactive-fields/CountrySelectbox";
import CitySelectbox from "@/components/interactive-fields/CitySelectbox";
import DistrictOutlineSelectbox from "@/components/interactive-fields/DistrictOutlineSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import UniversityWithFilterSelectbox from "@/components/interactive-fields/UniversityWithFilterSelectbox";
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
import ScholarshipRateSelectbox from "@/components/interactive-fields/ScholarshipRateSelectbox";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import ProgramService from "@/services/ProgramService";
import RegistrationService from "@/services/RegistrationService";

import SchoolSelectbox from "@/components/interactive-fields/SchoolSelectbox";
import SelectDate from "@/components/interactive-fields/SelectDate.vue";

import {mask} from 'vue-the-mask'
import qs from "qs";


export default {
    directives: {mask},
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        ValidationObserver,
        ValidationProvider,
        GenderSelectbox,
        CountrySelectbox,
        CitySelectbox,
        DistrictOutlineSelectbox,
        ParameterSelectbox,
        UniversityWithFilterSelectbox,
        SemestersSelectbox,
        ScholarshipRateSelectbox,
        FacultySelectbox,
        DepartmentSelectbox,
        ProgramSelectbox,
        SchoolSelectbox,

        SelectDate
    },
    metaInfo() {
        return {
            title: this.$t('new_register')
        }
    },
    data() {
        return {
            renderCount: 0,
            foreignStudent: false,
            tabIndex: 1,
            programLanguage: null,
            university_type: ["Yurt İçi", "Yurt Dışı"],
            registerForm: {
                semester_id: null,
                type: null,
                scholarship_rate: null,
                faculty: null,
                department: null,
                program_code: null,
                national_id: null,
                name: null,
                surname: null,
                birthdate: null,
                birthplace: null,
                gender: null,
                nationality_code: null,
                mother_name: null,
                father_name: null,
                city_id: null,
                district_id: null,
                foreign_document_type: null,
                foreign_document_number: null,
                passport_country_id: null,
                passport_number: null,
                residence_permit_no: null,
                residence_permit_start_date: null,
                residence_permit_end_date: null,
                contact_city_id: null,
                contact_district_id: null,
                contact_address: null,
                contact_address_city: null,
                contact_address_district: null,
                // contact_address: null,
                mothers_job: null,
                mothers_job_other: null,
                mothers_phone: null,
                mothers_education: null,
                fathers_education: null,
                fathers_job: null,
                fathers_job_other: null,
                fathers_phone: null,
                home_tel: null,
                mobile_tel: null,
                email: null,
                emergency_person_relation: null,
                emergency_person_name: null,
                emergency_person_phone: null,
                surgery_illness_history: null,
                medicines: null,
                type_of_disability: null,
                disability_rate: null,
                last_education_status: null,
                last_school_registration_date: null,
                last_university_id: null,
                last_school_date_of_graduation: null,
                last_school_diploma_number: null,
                last_school_diploma_point: null,
                last_school: null,
                want_leave_of_absence: null,
                reason_for_leave_of_absence: null,
                leave_of_absence_semesters: null,
                want_optional_prep: null,
                has_english_proficiency_certificate: null,
                english_proficiency_certificate_type: null,
                english_proficiency_exam_date: null,
                english_proficiency_exam_score: null,
                toefl_username: null,
                toefl_password: null,
            },

            leaveOfAbsenceSemesterOptions: [
                {value: null, text: this.$t('select')},
                {value: 1, text: this.$t('fall')},
                {value: 2, text: this.$t('spring')},
                {value: 3, text: this.$t('fall_and_spring')}
            ],
            upperAlphaMask: {
                mask: 'UUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUU',
                tokens: {
                    'U': {
                        pattern: /[A-Za-z ÇĞİÖŞÜçğıöşü]/,
                        transform: function (v) {
                            v = v.replaceAll('i', 'İ');
                            return v.toUpper();
                        }
                    }
                }
            },

        }
    },

    methods: {
        redirectTab() {
            let tab1 = this.$refs.tab1.$el.querySelector('.is-invalid,.box-border-color')
            let tab2 = this.$refs.tab2.$el.querySelector('.is-invalid,.box-border-color')
            let tab3 = this.$refs.tab3.$el.querySelector('.is-invalid,.box-border-color')
            let tab4 = this.$refs.tab4.$el.querySelector('.is-invalid,.box-border-color')
            let tab5 = this.$refs.tab5.$el.querySelector('.is-invalid,.box-border-color')
            let tab6 = this.$refs.tab6.$el.querySelector('.is-invalid,.box-border-color')
            let tab7 = this.$refs.tab7.$el.querySelector('.is-invalid,.box-border-color')


            if (tab1) return this.tabIndex = 0;
            if (tab2) return this.tabIndex = 1;
            if (tab3) return this.tabIndex = 2;
            if (tab4) return this.tabIndex = 3;
            if (tab5) return this.tabIndex = 4;
            if (tab6) return this.tabIndex = 5;
            if (tab7) return this.tabIndex = 6;
        },
        async sendForm() {
            const isValid = await this.$refs.registerForm.validate();
            this.redirectTab()
            if (isValid) {
                this.registerForm.mothers_job_other != null && this.registerForm.mothers_job_other != '' ? this.registerForm.mothers_job = this.registerForm.mothers_job_other : null
                this.registerForm.fathers_job_other != null && this.registerForm.fathers_job_other != '' ? this.registerForm.fathers_job = this.registerForm.fathers_job_other : null
                delete this.registerForm.university_status

                var formData = new FormData()

                for (const [key, value] of Object.entries(this.registerForm)) {
                    if (value != null) {
                        formData.append(key, value)
                    }
                }

                this.$swal({
                    title: this.$t('attention'),
                    text: this.$t('prep_classes_store_confirm_text'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                }).then(response => {
                    if (response.isConfirmed == true) {
                        RegistrationService.store(formData).then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message));
                            this.$router.push('/registrations/new/register/' + response.data.data.id + '/documents')
                        }).catch(e => {
                            this.showErrors(e, this.$refs.registerForm)
                            this.$nextTick(() => {
                                this.redirectTab()
                            })
                        })
                    }
                })
            }
        },

        changeStudentType() {
            this.foreignStudent = !this.foreignStudent
            this.$nextTick(() => {
                this.renderCount += 1;
            })
        },

        chooseStudentType() {
            this.foreignStudent = false
            this.registerForm.passport_number = null
        },

        isInterStudent(value) {
            if (value == 203 || value == 232 || value == 233 || value == 234 || value == 235 || value == 215 || value == 220) {
                this.foreignStudent = true

                this.registerForm.national_id = null
                this.registerForm.birthplace = null
                this.registerForm.birthdate = null
                this.registerForm.mother_name = null
                this.registerForm.city_id = null
                this.registerForm.district_id = null
            } else {
                this.foreignStudent = false

                this.registerForm.passport_country_id = null
                this.registerForm.passport_number = null
                this.registerForm.residence_permit_no = null
                this.registerForm.residence_permit_start_date = null
                this.registerForm.residence_permit_end_date = null
                this.registerForm.foreignDocumentType = null
                this.registerForm.foreignDocumentNumber = null
            }
        },

        setProgramLanguage(programCode) {
            ProgramService.get(programCode).then(response => {
                this.programLanguage = response.data.data.language
            })
        },

        handleJobInput(data, which) {
            if (data == 'Diğer') {
                if (which == 'mother') {
                    this.registerForm.mothers_job_other = null
                } else if (which == 'father') {
                    this.registerForm.fathers_job_other = null
                }
            }
        },


        getMernisInformation() {
            const config = {
                params: {
                    national_id:this.registerForm.national_id,
                    birthdate:this.registerForm.birthdate
                },
            };
            return RegistrationService.getMernisPersonInformation(config)
                .then((response) => {
                    const data =response.data.data
                    this.registerForm.national_id =data.national_id
                    this.registerForm.birthplace = data.birthplace
                    this.registerForm.birthdate = data.birthdate
                    this.registerForm.mother_name =data.mother_name
                    this.registerForm.father_name=data.father_name
                    this.registerForm.gender=data.gender
                    this.registerForm.marital_status=data.marital_status
                    this.registerForm.marital_status_text=data.marital_status_text
                    this.registerForm.mother_name=data.mother_name
                    this.registerForm.name=data.name
                    this.registerForm.surname=data.surname

                    this.getMernisAddressInformation()

                })
                .catch(err => {
                    this.showErrors(err)
                })
        },
        getMernisAddressInformation() {
            const config = {
                params: {
                    national_id:this.registerForm.national_id,
                    birthdate:this.registerForm.birthdate
                },
            };
            return RegistrationService.getMernisPersonAddressInformation(config)
                .then((response) => {
                    const data =response.data.data
                    this.registerForm.district=data.district
                    this.registerForm.district_code=data.district_code
                    this.registerForm.district_id = data.district_id
                    this.registerForm.contact_city_id = data.city_id
                    this.registerForm.city_id = data.city_id
                    this.registerForm.city=data.city
                    this.registerForm.city_code=data.city_code
                    this.registerForm.contact_district_id = data.district_id
                    this.registerForm.contact_address=data.address
                })
                .catch(err => {
                    this.showErrors(err)
                })
        }
    },

    watch: {
        'registerForm.program_code': {
            handler: function (val) {
                this.setProgramLanguage(val)
            }
        },

        'registerForm.type': {
            handler: function (val) {
                this.isInterStudent(val)
            }
        },
        foreignStudent: {
            handler: function (val) {
                if (!val) {
                    this.registerForm.nationality_code = 'TR';
                } else {
                    this.registerForm.nationality_code = null;
                }
            },
            immediate: true
        }
    },
    computed: {
        checkLastStatus: function () {
            return this.registerForm.last_education_status != 'high_school' ? true : false
        }
    },
    created() {
        this.chooseStudentType()
    }
};
</script>

